import { Chart,
    BarController, BarElement,
    LineController, LineElement, 
    PointElement, Filler,
    CategoryScale, LinearScale, Title, Tooltip, Legend, } from 'chart.js';

Chart.register(BarController, BarElement,
    LineController, LineElement, 
    PointElement, Filler,
    CategoryScale, LinearScale,  Title, Tooltip, Legend);

import Generic from "./Generic";

export default class Academic {
    /**
     * academic related methods
     */
    static iclassInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();
    }
    static sectionInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();
    }
    static subjectInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();
        $('#class_id_filter').on('change', function () {
            let class_id = $(this).val();
            let getUrl = window.location.href.split('?')[0];
            if(class_id){
                getUrl +="?class="+class_id;

            }
            window.location = getUrl;

        });
    }

    static checkboxUrlChanger (el) {
        let value = $(el).val();
        let name = $(el).attr('name');
        let urlLastPart = '';
        if(institute_category == 'college'){
            let ac_year = $('select[name="academic_year"]').val();
            if(!ac_year){
                toastr.error('Select academic year!');
                return false;
            }

            urlLastPart ="&academic_year="+ac_year;
        }
        var search = location.search.split('?');
        search = (search.length > 1)? search[1] : search[0];
        search = search.replace(new RegExp("\&?"+name+"\=.*\&?", "g"), '');
        var getUrl = window.location.href.split('?')[0]+"?"+search;
        if ($(el).is(":checked")) {
            getUrl = window.location.href.split('?')[0]+"?"+search+"&"+name+"="+value+urlLastPart;
        }
        window.location = getUrl;
    }

    static inputUrlChanger (el) {
        let value = $(el).val();
        let name = $(el).attr('name');
        let urlLastPart = '';
        if(institute_category == 'college'){
            let ac_year = $('select[name="academic_year"]').val();
            if(!ac_year){
                toastr.error('Select academic year!');
                return false;
            }

            urlLastPart ="&academic_year="+ac_year;
        }
        var search = location.search.split('?');
        search = (search.length > 1)? search[1] : search[0];
        search = search.replace(new RegExp("\&?"+name+"\=.*\&?", "g"), '');
        let getUrl = window.location.href.split('?')[0]+"?"+search+"&"+name+"="+value+urlLastPart;
        window.location = getUrl;
    }

    static admissionInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();

        $('select[name="class_id"]').on('change', function () {
            let class_id = $(this).val();
            let urlLastPart = '';
            if(institute_category == 'college'){
                let ac_year = $('select[name="academic_year"]').val();
                if(!ac_year){
                    toastr.error('Select academic year!');
                    return false;
                }

                urlLastPart ="&academic_year="+ac_year;
            }
            let getUrl = window.location.href.split('?')[0]+"?class="+class_id+urlLastPart;
            window.location = getUrl;

        });
        
        $(document).on('change', '.input-urlChanger', function() {
            Academic.inputUrlChanger(this);
        });
        
        $(document).on('click', '.checkbox-urlChanger', function() {
            Academic.checkboxUrlChanger(this);
        });
        
    }
    static studentInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();
        $('select[name="nationality"]').on('change', function () {
            // console.log('fire me');
            var value = $(this).val();
            if(value == 'Other'){
                $('input[name="nationality_other"]').prop('readonly', false);
                $('#other-nat').css('display', 'block');
            }
            else{
                $('input[name="nationality_other"]').val('');
                $('input[name="nationality_other"]').prop('readonly', true);
                $('#other-nat').css('display', 'none');
            }
        });

        $('#student_list_filter').on('change', function () {
            let class_id = $('select[name="class_id"]').val();
            let section_id = $(this).val();
            let urlLastPart = '';
            if(institute_category == 'college'){
                let ac_year = $('select[name="academic_year"]').val();
                if(!ac_year){
                    toastr.error('Select academic year!');
                    return false;
                }

                urlLastPart ="&academic_year="+ac_year;
            }
            if(class_id && section_id){
                let getUrl = window.location.href.split('?')[0]+"?class="+class_id+"&section="+section_id+urlLastPart;
                window.location = getUrl;

            }

        });
        $('select[name="academic_year"]').on('change', function () {
            $('#student_list_filter').trigger('change');
        });


    }

    static getStudentByAcYearAndClassAndSection(acYear=0, classId, sectionId, cb=function(){}) {
        let getUrl = window.getStudentAjaxUrl +"?academic_year="+acYear+"&class="+classId+"&section="+sectionId;
        axios.get(getUrl)
            .then((response) => {
                // console.log(response);
                cb(response.data);
            }).catch((error) => {
            let status = error.response.statusText;
            toastr.error(status);
            cb([]);
        });
    }

    /**
     * Student Attendance
     */
    static attendanceInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();

        $('select[name="class_id"]').on('change', function () {
            let class_id = $(this).val();
            Academic.getSection(class_id);
        });
        $('select[name="section_id"]').on('change', function () {
            $('#attendance_list_filter').trigger('dp.change');
        });
        $('#attendance_list_filter').on('dp.change', function (event) {
            let atDate = $(this).val();
            let classId = $('select[name="class_id"]').val();
            let sectionId = $('select[name="section_id"]').val();
            let acYearId = $('select[name="academic_year"]').val();

            if(institute_category == "college" && !acYearId){
                toastr.warning('Select academic year first!');
                return false;
            }

            let queryString = "?class="+classId+"&section="+sectionId+"&attendance_date="+atDate;
            if(institute_category == 'college'){
                queryString +="&academic_year="+acYearId;
            }

            let getUrl = window.location.href.split('?')[0]+queryString;
            window.location = getUrl;

        });

        $('.attendanceExistsChecker').on('dp.change', function (event) {
            Academic.checkAttendanceExists(function (data) {
                    if(data>0){
                        toastr.error('Attendance already exists!');
                    }
                    else{
                        $('#section_id_filter').trigger('change');
                    }
            });

        });
        $('#toggleCheckboxes').on('ifChecked ifUnchecked', function(event) {
            if (event.type == 'ifChecked') {
                $('input:checkbox:not(.notMe)').iCheck('check');
            } else {
                $('input:checkbox:not(.notMe)').iCheck('uncheck');
            }
        });

        $('#section_id_filter').on('change', function () {
            //hide button
            let sectionId = $(this).val();
            let classId =  $('select[name="class_id"]').val();
            let acYearId =  $('select[name="academic_year"]').val();
            //validate input
            if(!classId || !sectionId){
                return false;
            }
            //check year then procced
            if(institute_category == "college"){
                if(!acYearId) {
                    toastr.warning('Select academic year first!');
                    return false;
                }
            }
            else {
                acYearId = 0;
            }

            Generic.loaderStart();
            Academic.checkAttendanceExists(function (data) {
                if(data>0){
                    toastr.error('Attendance already exists!');
                }

                Generic.loaderStop();

            });


        });

        $('input.inTime').on('dp.change', function (event) {
            let attendance_date = window.moment($('input[name="attendance_date"]').val(),'DD-MM-YYYY');
            let inTime =  window.moment(event.date,'DD-MM-YYYY');
            if(inTime.isBefore(attendance_date)){
                toastr.error('In time can\'t be less than attendance date!');
                $(this).data("DateTimePicker").date(attendance_date.format('DD/MM/YYYY, hh:mm A'));
                return false;
            }

            let timeDiff = window.moment.duration(inTime.diff(attendance_date));
            if(timeDiff.days()>0){
                toastr.error('In time can\'t be greater than attendance date!');
                $(this).data("DateTimePicker").date(attendance_date.format('DD/MM/YYYY, hh:mm A'));
                return false;
            }

        });

        $('input.outTime').on('dp.change', function (event) {
            let inTime = window.moment($(this).parents('tr').find('input.inTime').val(),'DD-MM-YYYY, hh:mm A');
            let outTime =  window.moment(event.date,'DD-MM-YYYY, hh:mm A');

            if(outTime.isBefore(inTime)){
                toastr.error('Out time can\'t be less than in time!');
                $(this).data("DateTimePicker").date(inTime);
                return false;
            }
            let timeDiff = window.moment.duration(outTime.diff(inTime));
            if(timeDiff.days()>0){
                toastr.error('Can\'t stay more than 24 hrs!');
                $(this).data("DateTimePicker").date(inTime);
                return false;
            }
            let workingHours = [timeDiff.hours(), timeDiff.minutes()].join(':');
            $(this).parents('tr').find('span.stayingHour').text(workingHours);

        });
    }

    static checkAttendanceExists(cb={}) {
        let atDate = $('input[name="attendance_date"]').val();
        let classId = $('select[name="class_id"]').val();
        let sectionId = $('select[name="section_id"]').val();
        let acYearId = $('select[name="academic_year"]').val();
        let queryString = "?class="+classId+"&section="+sectionId+"&attendance_date="+atDate;

        if(institute_category == 'college'){
            queryString +="&academic_year="+acYearId;
        }

        let getUrl = window.attendanceUrl + queryString;
        axios.get(getUrl)
            .then((response) => {
                cb(response.data);
            }).catch((error) => {
            let status = error.response.statusText;
            toastr.error(status);
            cb(0);
            Generic.loaderStop();
        });

    }

    static attendanceFileUploadStatus() {
        // progress status js code here
        $.ajax({
            'url': window.fileUploadStatusURL,
        }).done(function(r) {
            if(r.success) {
                $('#statusMessage').html(r.msg);
                setTimeout(function () {
                    window.location.reload();
                }, 5000);
            } else {
                $('#statusMessage').html(r.msg);
                if(r.status == 0){
                    setTimeout(function () {
                        Academic.attendanceFileUploadStatus();
                    }, 500);
                }
                else if(r.status == -1){
                    $('.progressDiv').removeClass('alert-info');
                    $('.progressDiv').addClass('alert-danger');
                    $('#spinnerspan').remove();
                }

            }
        }).fail(function() {
                $('#statusMessage').html("An error has occurred...Contact administrator" );
            });

    }
    
    static studentAttendanceTab(el) {
        if (el == undefined) {
            el = '#tabAttendance';
        }
        $(el).click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.attendanceUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#attendanceTable tbody').empty();
            axios.get(geturl)
                .then((response) => {
                    // console.log(response);
                    if(response.data.length){
                        response.data.forEach(function (item) {
                            let color = item.present == "Present" ? 'bg-green' : 'bg-red';
                            let trrow = ' <tr>\n' +
                                '  <td class="text-center">'+item.attendance_date+'</td>\n' +
                                '  <td class="text-center">'+(new Date(item.in_time)).toLocaleTimeString()+'</td>\n' +
                                '  <td class="text-center"> <span class="badge '+ color+'">'+item.status+'</span></td>\n' +
                                '</tr>';

                            $('#attendanceTable tbody').append(trrow);
                        });
                    }

                    Generic.loaderStop();
                }).catch((error) => {
                let status = error.response.statusText;
                toastr.error(status);
                Generic.loaderStop();
            });
        });
    }

    static studentProfileInit() {
        $('.btnPrintInformation').click(function () {
            $('ul.nav-tabs li:not(.active)').addClass('no-print');
            $('ul.nav-tabs li.active').removeClass('no-print');
            window.print();
        });
        
        Academic.studentAttendanceTab();
        
        $('#tabSanctions').on('click', function () {
            $('#tabAllSanctions').click();
            // $('#tabSuspensions').click();
        });
        
        

        $('#tabAllSanctions').click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.sanctionUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#sanctionTable tbody').empty();
            console.log('Sanction Url: ', geturl);
            axios.get(geturl)
                .then((response) => {
                    // console.log('response?');
                    // console.log(response);
                    if(response.data.length){
                        response.data.forEach(function (sanction) {
                            let trrow = ' <tr class="student-sanction-field" title="Open Sanction Details: ID '+sanction.id+'" data-key="'+sanction.id+'">\n' +
                                '  <td class="text-center">'+sanction.id+'</td>\n' +
                                '  <td class="text-center">'+sanction.created_at.split('T')[0]+'</td>\n' +
                                '  <td class="text-center"> '+sanction.offense+'</td>\n' +
                                '  <td class="text-center"> '+sanction.description+'</td>\n' +
                                '  <td class="text-center"> '+sanction.creator.name+'</td>\n' +
                                '</tr>';

                            $('#sanctionTable tbody').append(trrow);
                        });
                    }else{
                        console.log('Not array')
                    }
                    let reportHtml = $('#studentOffenseMod').html();
                    $('input[name="student_report"]').val(reportHtml);

                    Generic.loaderStop();
                }).catch((error) => {
                let status = error.message;
                console.log('Error status: ', status);

                toastr.error(status);
                Generic.loaderStop();
            });
        });

        $('#tabSuspensions').click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.suspensionUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#suspensionTable tbody').empty();
            console.log('Suspension Url: ', geturl);
            axios.get(geturl)
                .then((response) => {
                   // console.log(response);
                    if(response.data.length){
                        response.data.forEach(function (sanction) {
                            let trrow = ' <tr>\n' +
                                '  <td class="text-center">'+sanction.id+'</td>\n' +
                                '  <td class="text-center">'+sanction.start_date+'</td>\n' +
                                '  <td class="text-center"> '+sanction.end_date+'</td>\n' +
                                '  <td class="text-center"> '+sanction.description+'</td>\n' +
                                '  <td class="text-center"> '+sanction.creator.name+'</td>\n' +
                                '</tr>';

                            $('#suspensionTable tbody').append(trrow);
                        });
                    }

                    Generic.loaderStop();
                }).catch((error) => {
                // let status = (error.response.statusText)? error.response.statusText : error.response.data.message;
                let status = error.message;
                console.log('Error status: ', status);
                toastr.error(status);
                Generic.loaderStop();
            });
        });

        $('#tabDeans').on('click', function () {
            $('#tabAllReports').click();
        });
        $('#tabAllReports').on('click', function () {
            let id = $(this).attr('data-pk');
            let geturl = window.deanInputUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#reportTable tbody').empty();
            console.log('Sanction Url: ', geturl);
            axios.get(geturl)
                .then((response) => {
                    // console.log('response?');
                    // console.log(response);
                    // console.log('response data length');
                    // console.log(response.data.length);
                    if(response.data.length){
                        response.data.forEach(function (report) {
                            let repDate = report.created_at;
                            let trrow = ' <tr class="student-d-report-field" title="Open Report Details: ID '+report.id+'" data-key="'+report.id+'">\n' +
                                '  <td class="text-center">'+report.id+'</td>\n' +
                                '  <td class="text-center">'+repDate.split('T')[0]+'</td>\n' +
                                '  <td class="text-center"> '+report.offense+'</td>\n' +
                                '  <td class="text-center"> '+report.description+'</td>\n' +
                                '  <td class="text-center"> '+report.creator.name+'</td>\n' +
                                '</tr>';

                            $('#reportTable tbody').append(trrow);
                        });
                    }else{
                        console.log('Not array')
                    }
                    // let reportHtml = $('#studentNonOffenseMod').html();
                    // $('input[name="student_report"]').val(reportHtml);

                    Generic.loaderStop();
                }).catch((error) => {
                let status = error.message;
                console.log('Error status: ', status);

                toastr.error(status);
                Generic.loaderStop();
            });
        });

        $('#tabMessages').click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.messageUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#messageTable tbody').empty();
            axios.get(geturl)
                .then((response) => {
                    // console.log(response);
                    if(response.data.length){
                        response.data.forEach(function (message) {
                            let trrow = ' <tr>\n' +
                                '  <td class="text-center">'+message.message+'</td>\n' +
                                '  <td class="text-center"> '+message.created_at+'</td>\n' +
                                '  <td class="text-center"> '+message.status+'</td>\n' +
                                '</tr>';

                            $('#messageTable tbody').append(trrow);
                        });
                    }

                    Generic.loaderStop();
                }).catch((error) => {
                let status = (error.response.statusText)? error.response.statusText : error.response.data.message;
                toastr.error(status);
                Generic.loaderStop();
            });
        });

        $('#tabTemperature').click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.temperatureUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#temperatureTable tbody').empty();
            window.temperatureDataLabel = {};
            axios.get(geturl)
                .then((response) => {
                    // console.log(response);
                   if(response.data.length){
                       response.data.forEach(function (data) {
                          let trrow = ' <tr>\n' +
                              '  <td class="text-center"><span class="celsius">'+data.temperature+'</span></td>\n' +
                              '  <td class="text-center"> '+data.created_at+'</td>\n'
                              '</tr>';

                           $('#temperatureTable tbody').append(trrow);
                           var date = data.created_at.split(" ")[0];
                           window.temperatureDataLabel[date] = data.temperature;
                       });
                       
                       Academic.initTemperatureChart(
                           'temperatureChart',
                           {
                                labels:  Object.keys(window.temperatureDataLabel).reverse(),
                                datasets: [{
                                    label: 'Temperature',
                                    data: Object.values(window.temperatureDataLabel).reverse(),
                                    backgroundColor: "rgb(54, 162, 235)",
                                    borderColor: "rgb(54, 162, 235)",
                                    fill: false,
                                    pointRadius: 6,
                                    pointHoverRadius: 20,
                                }]
                            }
                       );
                   }

                    Generic.loaderStop();
                }).catch((error) => {
                let status = (error.response.statusText)? error.response.statusText : error.response.data.message;
                toastr.error(status);
                Generic.loaderStop();
            });
        });

        $('#tabLunch').click(function () {
            let id = $(this).attr('data-pk');
            let geturl = window.lunchUrl+'?student_id='+id;
            Generic.loaderStart();
            $('#lunchTable tbody').empty();
            axios.get(geturl)
                .then((response) => {
                    // console.log(response);
                    if(response.data.length){
                        response.data.forEach(function (lunch) {
                            var trrow = ' <tr>\n' +
                                '  <td class="text-center">'+lunch.receipt_time+'</td>\n'
                                + (parseInt(lunch.status)?
                                '  <td class="text-center"><i style="color: green;" class="fa fa-check-square-o" aria-hidden="true"></i></td>\n'
                                    :
                                '  <td class="text-center"><i style="color: red;" class="fa fa-times-circle-o" aria-hidden="true"></i></td>\n'
                            )
                            + '  <td class="text-center"> '
                                +((lunch.description)? lunch.description : '')+'</td>\n'
                                +'</tr>';

                            $('#lunchTable tbody').append(trrow);
                        });
                    }

                    Generic.loaderStop();
                }).catch((error) => {
                let status = (error.response.statusText)? error.response.statusText : error.response.data.message;
                toastr.error(status);
                Generic.loaderStop();
            });
        });
    }
    
    static initTemperatureChart(el, data, options) {
        var chartElement = document.getElementById(el);

        if (!data) {
            data = {
                labels:  window.temperatureLabel,
                datasets: [{
                    label: 'Temperature',
                    data: window.temperatureData,
                    backgroundColor: "rgb(54, 162, 235)",
                    borderColor: "rgb(54, 162, 235)",
                    fill: false,
                    pointRadius: 6,
                    pointHoverRadius: 20,
                }]
            };
        }
        
        if (!options) {
            options = {
                responsive: true,
                legend: {
                    position: 'top',
                },
                hover: {
                    mode: 'index'
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Date'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Degree Celsius'
                        },
                        beginAtZero: true,
                        ticks: {
                            precision: 0, // Ensures whole numbers
                            stepSize: 1 // Minimum step size set to 1
                        }
                    }
                },
                title: {
                    display: false,
                    text: 'Student Temperature'
                }
            }
        }

        if(chartElement) {
            var config = {
                type: 'line',
                data: data,
                options: options
            };
            var ctx = chartElement.getContext('2d');
            new Chart(ctx, config);
        }
    }

    static examRuleInit() {
        Generic.initCommonPageJS();
        Generic.initDeleteDialog();
        $('#exam_rules_add_class_change').on('change', function () {
            //get subject of requested class
            Generic.loaderStart();
            let class_id = $(this).val();
            Academic.getSubject(class_id, 0, function (res={}) {
                // console.log(res);
                if (Object.keys(res).length){
                    $('select[name="subject_id"]').empty().prepend('<option selected=""></option>').select2({placeholder: 'Pick a subject...', data: res});
                    $('select[name="combine_subject_id"]').empty().prepend('<option selected=""></option>').select2({placeholder: 'Pick a subject...', data: res});
                }
                else{
                    // clear subject list dropdown
                    $('select[name="subject_id"]').empty().select2({placeholder: 'Pick a subject...'});
                    $('select[name="combine_subject_id"]').empty().select2({placeholder: 'Pick a subject...'});
                    toastr.warning('This class have no subject!');
                }
                Generic.loaderStop();
            });

            //now fetch exams for this class
            Academic.getExam(class_id);

        });

        $('select[name="exam_id"]').on('change', function () {
            $('#distributionTypeTable tbody').empty();
            if($(this).val()) {
                let getUrl = window.exam_details_url + "?exam_id=" + $(this).val();
                Generic.loaderStart();
                axios.get(getUrl)
                    .then((response) => {
                        // console.log(response.data);
                        response.data.forEach(function (item) {
                            let trrow = '<tr>\n' +
                                ' <td>\n' +
                                ' <span>' + item.text + '</span>\n' +
                                ' <input type="hidden" name="type[]" value="' + item.id + '">\n' +
                                ' </td>\n' +
                                ' <td>\n' +
                                '<input type="number" class="form-control" name="total_marks[]" value="" required min="0">\n' +
                                '</td>\n' +
                                ' <td>\n' +
                                '<input type="number" class="form-control" name="pass_marks[]" value="0" required min="0">\n' +
                                '</td>\n' +
                                '</tr>';

                            $('#distributionTypeTable tbody').append(trrow);
                        });
                        Generic.loaderStop();
                    }).catch((error) => {
                    let status = error.response.statusText;
                    toastr.error(status);
                    Generic.loaderStop();
                });
            }
        });

        function fetchGradeInfo() {
            $('input[name="total_exam_marks"]').val('');
            $('input[name="over_all_pass"]').val('');
            let gradeId =  $('select[name="grade_id"]').val();
            if(gradeId) {
                let getUrl = window.grade_details_url + "?grade_id=" + gradeId;
                Generic.loaderStart();
                axios.get(getUrl)
                    .then((response) => {
                        // console.log(response.data);
                        $('input[name="total_exam_marks"]').val(response.data.totalMarks);
                        $('input[name="over_all_pass"]').val(response.data.passingMarks);
                        Generic.loaderStop();
                    }).catch((error) => {
                    let status = error.response.statusText;
                    toastr.error(status);
                    Generic.loaderStop();
                });
            }
        }

        $('select[name="grade_id"]').on('change', function () {
            fetchGradeInfo();
        });
        $('select[name="combine_subject_id"]').on('change', function () {
            let subjectId =  $('select[name="subject_id"]').val();
            let combineSujectId = $(this).val();

            if(subjectId==combineSujectId){
                toastr.error("Same subject can not be a combine subject!");
                $('select[name="combine_subject_id"]').val('').trigger('change');
            }
        });
        $('select[name="passing_rule"]').on('change', function () {
            let passingRule = $(this).val();
            if(passingRule == 2) {
                // individual pass
                $('input[name="over_all_pass"]').val(0);
                $('input[name="pass_marks[]"]').prop('readonly', false);
                $('input[name="pass_marks[]"]').val(0);
            }
            else{
                if($('input[name="over_all_pass"]').val() == 0){
                    fetchGradeInfo();
                }
                $('.overAllPassDiv').show();
            }

            if(passingRule == 1){
                $('input[name="pass_marks[]"]').prop('readonly', true);
            }
            else{
                $('input[name="pass_marks[]"]').prop('readonly', false);
                $('input[name="pass_marks[]"]').val(0);
            }
        });

        //
        $('html').on('change keyup paste','input[name="total_marks[]"]', function(){
            let grandTotalMakrs = parseInt($('input[name="total_exam_marks"]').val());
            let distributionTotalMarks = 0;
            $('input[name="total_marks[]"]').each(function (index,element) {
                if($(element).val().length) {
                    distributionTotalMarks += parseInt($(element).val());
                }
            });
            // console.log(grandTotalMakrs, distributionTotalMarks);
            if(distributionTotalMarks> grandTotalMakrs){
                toastr.error("Marks distribution is wrong! Not match with total marks.");
                $('input[name="total_marks[]"]').val(0);
            }
        });

        //list page js
        $('select[name="class"]').on('change', function () {
            let classId = $(this).val();
            if(classId){
                //now fetch exams for this class
                Generic.loaderStart();
                let getUrl = window.exam_list_url + "?class_id=" + classId;
                axios.get(getUrl)
                    .then((response) => {
                        if (Object.keys(response.data).length) {
                            $('select[name="exam"]').empty().prepend('<option selected=""></option>').select2({allowClear: true,placeholder: 'Pick a exam...', data: response.data});
                        }
                        else {
                            $('select[name="exam"]').empty().select2({placeholder: 'Pick a exam...'});
                            toastr.error('This class have no exam!');
                        }
                        Generic.loaderStop();
                    }).catch((error) => {
                    let status = error.response.statusText;
                    toastr.error(status);
                    Generic.loaderStop();

                });
            }
            else{
                $('select[name="exam"]').empty().select2({placeholder: 'Pick a exam...'});
            }
        });
        $('#exam_rule_list_filter').on('change', function () {
            let classId =  $('select[name="class"]').val();
            let examId =  $('select[name="exam"]').val();
            if(classId && examId){
                let getUrl = window.location.href.split('?')[0]+"?class_id="+classId+"&exam_id="+examId;
                window.location = getUrl;
            }
        });
    }

    static getExam(class_id) {
        let getUrl = window.exam_list_url + "?class_id=" + class_id;
        if (class_id) {
            Generic.loaderStart();
            axios.get(getUrl)
                .then((response) => {
                    if (Object.keys(response.data).length) {
                        $('select[name="exam_id"]').empty().prepend('<option selected=""></option>').select2({allowClear: true,placeholder: 'Pick a exam...', data: response.data});
                    }
                    else {
                        $('select[name="exam_id"]').empty().select2({placeholder: 'Pick a exam...'});
                        toastr.error('This class have no exam!');
                    }
                    Generic.loaderStop();
                }).catch((error) => {
                let status = error.response.statusText;
                toastr.error(status);
                Generic.loaderStop();

            });
        }
        else {
            // clear section list dropdown
            $('select[name="exam_id"]').empty().select2({placeholder: 'Pick a exam...'});
        }
    }

    static marksInit() {
        Generic.initCommonPageJS();
        $("#markForm").validate({
            errorElement: "em",
            errorPlacement: function (error, element) {
                // Add the `help-block` class to the error element
                error.addClass("help-block");
                error.insertAfter(element);

            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            }
        });
        $('#class_change').on('change', function () {
            let class_id = $(this).val();
            if(class_id) {
                //get sections
                Academic.getSection(class_id);
                //get subject of requested class
                Generic.loaderStart();
                Academic.getSubject(class_id, 0, function (res = {}) {
                    // console.log(res);
                    if (Object.keys(res).length) {
                        $('select[name="subject_id"]').empty().prepend('<option selected=""></option>').select2({
                            allowClear: true,
                            placeholder: 'Pick a subject...',
                            data: res
                        });
                    }
                    else {
                        // clear subject list dropdown
                        $('select[name="subject_id"]').empty().select2({placeholder: 'Pick a subject...'});
                        toastr.warning('This class have no subject!');
                    }
                    Generic.loaderStop();
                });

                //get sections
                Academic.getExam(class_id);
            }
            else{
                $('select[name="section_id"]').empty().select2({placeholder: 'Pick a section...'});
                $('select[name="subject_id"]').empty().select2({placeholder: 'Pick a subject...'});
                $('select[name="exam_id"]').empty().select2({placeholder: 'Pick a exam...'});
            }

        });

        $('input[type="number"]').on('change keyup paste', function () {
            let marksElements = $(this).closest('tr').find('input[type="number"]');
            let totalMarks = 0;
            marksElements.each(function (index, element) {
                let marks = parseFloat($(element).val());
                if(marks){
                    totalMarks += marks;
                }
            });
            $(this).closest('tr').find('input.totalMarks').val(totalMarks.toFixed(2));
        });

        var title = $('title').text() + $('select[name="class_id"] option[selected]').text();
        title += '-'+ $('select[name="section_id"] option[selected]').text();
        title += '-'+ $('select[name="subject_id"] option[selected]').text();
        title += '-'+ $('select[name="exam_id"] option[selected]').text();
        $('title').text(title);

    }

    static resultInit() {
        Generic.initCommonPageJS();
        $('#class_change').on('change', function () {
            let class_id = $(this).val();
            if(class_id) {
                if(!window.generatePage) {
                    //get sections
                    Academic.getSection(class_id);
                }
                //get sections
                Academic.getExam(class_id);
            }
            else{
                $('select[name="section_id"]').empty().select2({placeholder: 'Pick a section...'});
                $('select[name="exam_id"]').empty().select2({placeholder: 'Pick a exam...'});
            }

        });
        var title = $('title').text() + $('select[name="class_id"] option[selected]').text();
        if($('select[name="section_id"]').val()) {
            title += '-' + $('select[name="section_id"] option[selected]').text();
        }
        title += '-'+ $('select[name="exam_id"] option[selected]').text();
        $('title').text(title);

        //marksheetview button click
        $('.viewMarksheetPubBtn').click(function (e) {
            e.preventDefault();
            postForm(this)

        });

        function postForm(btnElement) {
            let regiNo = $(btnElement).attr('data-regino');
            let pubMarksheetBtn = $(btnElement).hasClass( "viewMarksheetPubBtn" );
            let classId = $('select[name="class_id"]').val();
            let examId = $('select[name="exam_id"]').val();
            let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
            let formHtml = '<form id="marksheedForm" action="'+window.marksheetpub_url+'" method="post" target="_blank" enctype="multipart/form-data">\n' +
                '    <input type="hidden" name="_token" value="'+csrf+'">\n' +
                '    <input type="hidden" name="class_id" value="'+classId+'">\n' +
                '    <input type="hidden" name="exam_id" value="'+examId+'">\n' +
                '    <input type="hidden" name="regi_no" value="'+regiNo+'">\n';
            if(pubMarksheetBtn){
                formHtml += '    <input type="hidden" name="authorized_form" value="1">\n';
            }
            formHtml += '</form>';

            $('body').append(formHtml);
            $('#marksheedForm').submit();
            $('#marksheedForm').remove();
        }
    }
}